export const images = [
    {picture: "./7.jpg", date: 1799915600000, info: 'Приветствуем Вас, друзья!!  У нас хорошие новости для всех любителей электронной музыки!!! \n' +
            '\n' +
            'SeemSeem и DJ школа Format открывают двери для тех, кто давно мечтал оказаться по другую сторону пульта.\n' +
            'Программа обучения подойдёт тем, кто совсем не обладает ни какими знаниями и тем, кто уже делает первые шаги в диджеинге, но чувствует, что нуждается прокачке своих навыков.\n' +
            'Записаться на собеседование и узнать подробности можно написав нам в telegram @SeemSeemMusic', poster: 4.5},
    {video: "./3.MP4", date: 1732294800000, info: '22 ноября уже традиционный на привоз в SeemSeem \n' +
            'Мистический проект «Aiwaska» @aiwaska - это \n' +
            'мелодиктехно саунд с элементами органических звуков. Он отправит вас в незабываемое путешествие.\n' +
            'В основе образа артиста - маска, которая играет ключевую роль в его легенде. Магическая маска нужна для путешествий в параллельные миры. Она скрывает истинное лицо героя и выдаёт его за духа. Духи-проводники принимают его в маске за одного из них и пропускают в другой мир. Герой платит за услугу в мире духов монетами на шляпе.\n' +
            'Проект включает благотворительную деятельность. В стоимость проекта включены пожертвования в поддержку фондов по защите природы и окружающей среды.\n' +
            'Проект «Аiwaska» издаёт свои треки на музыкальных лейблах: «Crosstown Rebels», «Exploited”, “Bar25”.\n' +
            'Его треки играют в своих сетах такие артисты, как: Damian Lazarus, Adam Port, &Me, Rampa, AudioFly, Richie Hawtin, Tensnake и многие другие.\n' +
            'За 2019 год Aiwaska играл свои сеты в США и Европе, на фестивале Burning Man и Odessey Music Festival.\n' +
            'Следите, подписывайтесь и поддерживайте движение Aiwaska Safe The Planet!\n' +
            '\n' +
            '23-1 - Latro\n' +
            '1-3 - Apris \n' +
            '3-5 - Aiwaska \n' +
            '5-7 - Nagval\n' +
            '\n' +
            'Старт:23-00\n' +
            'Вход: предпродажа 1000₽, на дверях 🚪от 1500₽ \n' +
            'Инфо, столы, билеты 🎫 в директ‼️', poster: './3.jpg'},
];
