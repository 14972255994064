import React from "react";

const MODAL_STYLES = {
    top: 32,
    position: "absolute",
    backgroundColor: "#000",
    padding: "15px",
    zIndex: "1000",
    width: "80%",
    borderRadius: ".5em",
    color: '#FFF',
    whiteSpace: 'pre-line',
};
const OVERLAY_STYLE = {
    position: "fixed",
    display: "flex",
    justifyContent: "center",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0,0,0, .8)",
    zIndex: "1000",
    overflowY: "auto"
};
const Modal = ({ open, setModalOpened, children }) => {
    if (!open) return null;
    return (
        <>
            <div style={OVERLAY_STYLE} onClick={() => setModalOpened(false)}>
                <div style={MODAL_STYLES}>{children}</div>
            </div>
        </>
    );
};

export default Modal;
